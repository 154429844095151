import axios from '@/plugins/axios'

export default {
  async get(customerId) {
    return await axios.get(`customers/${customerId}/contract`);
  },

  async update(customerId, data) {
    return await axios.put(`customers/${customerId}/contract`, data);
  }
};
