<template>
  <div>
    <v-row class="pa-3">
      <v-col>
        <validation-observer ref="observer">
          <v-row>
            <v-col
              md="3"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="number"
              >
                <v-text-field
                  v-model="contract.number"
                  :disabled="loadingGet"
                  hide-details="auto"
                  label="Número"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              md="3"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="monthlyValue"
              >
                <v-text-field
                  v-model="contract.monthlyValue"
                  :disabled="loadingGet"
                  hide-details="auto"
                  label="Valor mensal"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              md="3"
            >
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="startDate"
                  >
                    <v-text-field
                      v-model="startDateFormated"
                      v-mask="'##/##/####'"
                      label="Data inicial"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="contract.startDate = dateFormatIso(startDateFormated)"
                      @click:append="startDateMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="contract.startDate"
                  no-title
                  class="ma-0"
                  @input="startDateMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col
              md="3"
            >
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="startDate"
                  >
                    <v-text-field
                      v-model="endDateFormated"
                      v-mask="'##/##/####'"
                      label="Data final"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="contract.startDate = dateFormatIso(endDateFormated)"
                      @click:append="endDateMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="contract.endDate"
                  no-title
                  class="ma-0"
                  @input="endDateMenu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="conditions"
              >
                <v-textarea
                  v-model="contract.conditions"
                  :disabled="loadingGet"
                  hide-details="auto"
                  label="Condições"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-col>
    </v-row>
    <v-divider class="mt-4" />
    <v-row>
      <v-col
        cols="12"
        class="pa-5 text-left"
      >
        <btn-save
          :loading="loadingSave"
          @click="save"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import customersContractApi from '@/api/customers-contract'
  import dateFormat from '@/utils/date-format'

  export default {

    props: {
      customer: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        loadingGet: false,
        loadingSave: false,
        contract: {
          startDate: null,
        },
        dateFormatIso: dateFormat.iso,
        startDateMenu: false,
        endDateMenu: false,
        startDateFormated: null,
        endDateFormated: null,
      }
    },

    mounted () {
      this.load()
    },

    watch: {
      'contract.startDate': {
        immediate: true,
        handler (newVal) {
          this.startDateFormated = dateFormat.ptBr(newVal)
        },
      },
      'contract.endDate': {
        immediate: true,
        handler (newVal) {
          this.endDateFormated = dateFormat.ptBr(newVal)
        },
      },
    },

    methods: {
      async load () {
        try {
          this.loadingGet = true
          const response = await customersContractApi.get(this.$route.params.id)
          this.contract = response.data.contract || {}
        } catch (e) {
          this.$router.push('/customers', () => this.$snackbar.show({ color: 'error', message: this.$apiError._(e) }))
        } finally {
          this.loadingGet = false
        }
      },

      async save () {
        try {
          this.loadingSave = true
          await customersContractApi.update(this.customer.id, this.contract)          
          this.$snackbar.show({ color: 'success', message: 'Contrato atualizado com sucesso' })
        } catch (e) {
          console.log(e)
          if (e.response.status === 422) {
            // this.formValidation = this.$apiError.fieldsValidation(e)
            // return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

    },

  }

</script>
